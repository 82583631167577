.indexChallenges {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    /* background-color: red; */
}
.challengesPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
    /* height: 450px; */
    background: rgb(204, 204, 204);
    border-radius: 10px;
    border: 2px solid #ffffff;
    padding: 40px;
}
.challengesPanelTitle {
    font-size: 26px;
    font-weight: 700;
    color: black;
}

.accProfileInputLabel {
    font-size: 14px;
    font-weight: 600;
    color: #d9cbd5;
    /* padding: 5px 0px 5px 0px; */
}
.accProfileInputStyle {
    background-color: #ffffff;
    padding: 7px 10px;
    color: #919091;
    font-weight: 600;
    border-radius: 9px;
    width: 100%;
    border: 2px solid  rgb(183 183 183);
    margin: 5px 0;
}
.accProfileButton {
    margin-top: 20px;
    background-color: red;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    width: 100%;
    text-align: center;
    padding: 10px 0;
    border-radius: 15px;
    transition: all .5s;
    cursor: pointer;
}
.accProfileButton:hover {
    background-color: rgb(109, 35, 35);
}
